<template>
  <div class="search-box">
    <el-form :inline="true" :model="queryForm" class="demo-form-inline">
      <el-form-item label="角色名称">
        <el-input v-model="queryForm.roleName" placeholder="" size="small"></el-input>
      </el-form-item>
      <el-form-item label="状态">
        <el-select v-model="queryForm.status" placeholder="" size="small" clearable>
          <el-option v-for="s in queryForm.statusOption" :label="s.label" :value="s.value" :key="s.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button icon="el-icon-search" type="primary" @click="submit" size="small">查询</el-button>
        <el-button icon="el-icon-plus" @click="addRole" size="small" v-show="hasAddPermision">新增角色</el-button>
      </el-form-item>
    </el-form>
  </div>
  <div class="data-box">
    <el-table v-loading="loading"
              element-loading-text="加载中..."
              element-loading-spinner="el-icon-loading"
              element-loading-background="rgba(0, 0, 0, 0.8)"
              :data="tableData" style="width: 100%;">
      <el-table-column prop="roleName" label="角色名称" />
      <el-table-column prop="seq" label="排序" />
      <el-table-column prop="status" label="状态" >
        <template #default="scope">
          <el-tag :type="scope.row.status === 0 ? 'info' : 'success'" disable-transitions>{{ scope.row.status==0?'禁用':'正常' }}</el-tag>
        </template>
      </el-table-column>

      <el-table-column label="操作" fixed="right" width="180" align="center" v-if="hasDeletePermision||hasEditPermision">
        <template #default="scope">
          <el-button icon="el-icon-edit" size="mini" @click="handleEdit(scope.$index, scope.row)" v-show="hasEditPermision">编辑</el-button>
          <el-popconfirm
              confirm-button-text="确定"
              cancel-button-text="取消"
              :title="'确定删除角色['+scope.row.roleName+']吗?'"
              @confirm="handleDelete(scope.$index, scope.row)"
          >
            <template #reference>
              <el-button icon="el-icon-delete" size="mini" type="danger" v-show="hasDeletePermision">删除</el-button>
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
  </div>
  <div class="page-box">
    <el-pagination
            v-model:currentPage="curPage"
            :page-sizes="[15, 50, 100, 200]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            background="true"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
    >
    </el-pagination>
  </div>

  <!--用户信息新增、修改弹窗-->
  <el-dialog
          v-model="dialogVisible"
          :title="dialogTitle"
          width="30%"
          @close="handleClose"
  >
    <el-form
            ref="ruleForm"
            :model="ruleForm"
            :rules="rules"
            label-width="80px"
            class="demo-ruleForm"
    >
      <el-form-item label="序号" prop="seq" class="label-color">
        <el-input-number v-model="ruleForm.seq" placeholder="请输入序号" :min="1" :readonly="seqReadOnly"></el-input-number>
      </el-form-item>
      <el-form-item label="角色名称" prop="roleName" class="label-color">
        <el-input v-model="ruleForm.roleName" placeholder="请输入角色名称" clearable ></el-input>
      </el-form-item>
      <el-form-item label="状态" prop="status" class="label-color">
        <el-radio-group v-model="ruleForm.status">
          <el-radio label="1">正常</el-radio>
          <el-radio label="0">禁用</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="菜单权限" prop="menuIds" class="label-color">
        <el-checkbox v-model="ruleForm.menuCheckStrictly" @change="handleCheckedTreeConnect($event, 'menu')">父子联动</el-checkbox>
        <el-tree class="tree-border" ref="tree" highlight-current="true" :data="menuTree" :check-strictly="!ruleForm.menuCheckStrictly"
                 show-checkbox
                 node-key="id"/>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="saveRoleInfo" v-if="isSave==true">保存</el-button>
        <el-button type="primary" @click="editRoleInfo" v-if="isSave==false">修改</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
  import roleApi from '@/api/system/role'
  import {ref} from "vue";
  import menuApi from "@/api/system/menu";
  import {handleTree} from "@/utils/treeUtil";

  export default {
    data(){
      return{
        hasAddPermision:false,
        hasEditPermision:false,
        hasDeletePermision:false,
        menuTree:[],
        queryForm:{
          seq:'',
          roleName:'',
          status:'',
          statusOption:[
            {
              label:'正常',
              value:'1'
            },
            {
              label:'禁用',
              value:'0'
            }
          ]
        },
        loading:false,
        total:0,
        pageSize:15,
        curPage:1,
        tableData: [],
        isSave:false,
        seqReadOnly:false,
        ruleForm: {
          seq:'',
          id:'',
          roleName: '',
          status: '',
          menuIds:[],
          selectMenuIds:[],
          menuCheckStrictly:true,
        },
        rules: {
          roleName: [
            {
              required: true,
              message: '请输入角色名称',
              trigger: 'blur',
            }
          ],
          seq: [
            {
              required: true,
              message: '请输入序号',
              trigger: 'blur',
            }
          ],
          status: [
            {
              required: true,
              message: '请选择状态',
              trigger: ['blur','change'],
            }
          ]
        },
        dialogTitle:'',
        dialogVisible:false
      }
    },
    mounted() {
      this.$nextTick(()=>{
        // 获取设置按钮权限
        this.hasAddPermision=this.$menuUtil.hasMenu('/system/roleManage:add');
        this.hasEditPermision=this.$menuUtil.hasMenu('/system/roleManage:edit');
        this.hasDeletePermision=this.$menuUtil.hasMenu('/system/roleManage:delete');
      })

      this.queryRole();
      this.queryMenuTree();
    },
    methods:{
      // 树权限（父子联动）
      handleCheckedTreeConnect(value) {
        this.ruleForm.menuCheckStrictly = value ? true: false;
      },
      queryMenuTree(){
        menuApi.queryMenu({
          name: '',
        }).then(response=>{
          this.menuTree=handleTree(response.data.data,'name');
        });
      },
      submit(){
        this.curPage=1;
        this.queryRole();
      },
      handleSizeChange(val){
        this.pageSize=val;
        this.queryRole();
      },
      handleCurrentChange(val){
        this.curPage=val;
        this.queryRole();
      },
      queryRole(){
        roleApi.queryRole({
          roleName: this.queryForm.roleName,
          status:this.queryForm.status,
          seq: this.queryForm.seq,
          pageSize: this.pageSize,
          curPage: this.curPage
        }).then(response=>{
          this.tableData=response.data.data.records;
          this.total=response.data.data.total;
        });
      },
      handleEdit(index,row){
        console.log(index,row)
        this.ruleForm.menuCheckStrictly = true;
        this.isSave=false;
        this.dialogTitle='编辑角色信息';
        this.dialogVisible=true;
        this.ruleForm.roleName=row.roleName;
        this.ruleForm.id=row.id;
        this.ruleForm.seq=row.seq;
        this.ruleForm.status=ref(row.status.toString());
        // 根据角色id查询菜单id
        roleApi.queryRoleMenu(row.id).then(response=>{
          if(response.data.code==1){
            this.ruleForm.selectMenuIds=response.data.data.menuIds;
            // 设置节点选中，因为直接设置default-checked-keys 三级子节点会全部选中
            this.ruleForm.selectMenuIds.forEach((v) => {
              this.$nextTick(()=>{
                this.$refs.tree.setChecked(v, true ,false);
              })
            })
          }
        });
      },
      addRole(){
        this.ruleForm.menuCheckStrictly = true;
        this.isSave=true;
        this.dialogTitle='新增角色';
        this.dialogVisible=true;
        this.ruleForm.seq=1;
        this.ruleForm.status=ref('1')
      },
      handleDelete(index,row){
        roleApi.delRole(row.id).then(response=>{
          if(response.data.code==1){
            this.$notify({
              title: response.data.msg,
              type: 'success',
              offset:40,
              duration: 2000
            });
            this.tableData.splice(index, 1)
          }else{
            this.$notify({
              title: response.data.msg,
              type: 'warning',
              offset:40,
              duration: 2000
            });
          }
        });
      },
      saveRoleInfo(){
        this.$refs['ruleForm'].validate((valid) => {
                  if (valid) {
                    // 获取选中的id  注意：要把parentid，也加到menuIds中，否则，在登录加载菜单时，父级加载不出来
                    this.ruleForm.menuIds=this.$refs.tree.getCheckedKeys().concat(this.$refs.tree.getHalfCheckedKeys());
                    // 调用接口保存
                    roleApi.addRole(this.ruleForm).then(response=>{
                      if(response.data.code==1){
                        this.$notify({
                          title: response.data.msg,
                          type: 'success',
                          offset:40,
                          duration: 2000
                        });
                        // 关闭窗口
                        this.dialogVisible=false;
                        // 重置表单
                        // this.$refs['ruleForm'].resetFields();
                        // 刷新列表
                        this.queryRole();
                      }else{
                        this.$notify({
                          title: '保存失败：'+response.data.msg,
                          type: 'warning',
                          offset:40,
                          duration: 2000
                        });
                      }
                    });
                  }
                }
        )
      },
      editRoleInfo(){
        this.$refs['ruleForm'].validate((valid) => {
                  if (valid) {
                    // 获取选中的id
                    this.ruleForm.menuIds=this.$refs.tree.getCheckedKeys().concat(this.$refs.tree.getHalfCheckedKeys());
                    // 调用接口保存
                    roleApi.editRole(this.ruleForm).then(response=>{
                      if(response.data.code==1){
                        this.$notify({
                          title: response.data.msg,
                          type: 'success',
                          offset:40,
                          duration: 2000
                        });
                        // 关闭窗口
                        this.dialogVisible=false;
                        // 重置表单
                        // this.$refs['ruleForm'].resetFields();
                        // 刷新列表
                        this.queryRole();
                      }else{
                        this.$notify({
                          title: '修改失败：'+response.data.msg,
                          type: 'warning',
                          offset:40,
                          duration: 2000
                        });
                      }
                    });
                  }
                }
        )
      },
      // 弹窗关闭事件
      handleClose(){
        this.ruleForm={};
        this.$refs.tree.setCheckedKeys([]);// 清空选中
      }
    }
  }
</script>

<style scoped>
/*菜单树样式*/
.tree-border {
  margin-top: 5px;
  border: 1px solid #e5e6e7;
  background: #FFFFFF none;
  border-radius:4px;
  min-height: 200px;
  max-height: 300px;
  overflow-y: scroll;
}
</style>