/**
 * 角色模块接口列表
 */
import request from '@/api/myaxios';
const role = {
    queryAllRole() {
        return request({
            url: '/api/role/queryAllRole',
            method: 'post'
        });
    },
    queryRole(data) {
        return request({
            url: '/api/role/queryRole',
            method: 'post',
            data: data
        });
    },
    addRole(data){
        return request({
            url: '/api/role/addRole',
            method: 'post',
            data: data
        });
    },
    editRole(data){
        return request({
            url: '/api/role/editRole',
            method: 'post',
            data: data
        });
    },
    delRole(id){
        return request({
            url: '/api/role/delRole/'+id,
            method: 'get',
        });
    },
    queryRoleMenu(roleId){
        return request({
            url: '/api/role/queryRoleMenu/'+roleId,
            method: 'get',
        });
    },

}

export default role;